import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/facilities.css"
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function WithOnePage(props) {
  return (
    <Layout>
      <Seo title="With One West" />
      <div className="contentBox">
        <div className="mobile-back-bttn">
          <AniLink cover to="/facilities">
            Back to Facilities
          </AniLink>
        </div>
        <h1 className="facility-title">With One West</h1>
        <div className="facility-container">
          <div className="facility-front-picture-container">
            <StaticImage
              src="../images/facilities/withone/withone-front1.jpg"
              alt="Frontyard"
              placeholder="blurred"
            />
          </div>
          <div className="facility-details-container">
            <div className="facility-detail-row">
              <p className="detail-title">EST</p>
              <p className="detail">2018</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Description</p>
              <p className="detail">Specialized Adult Day Program</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Services</p>
              <p className="detail">
                Intergrated Community Activities, 1:1 Staffing ratio,
                Independent Skill Development, Educational Activities,
                Vocational Activities, Social Activities
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Specialization</p>
              <p className="detail">
                Maintain health and to serve individuals with a multitude of
                severe, complex, and maladaptive behaviors requiring up to a one
                to one staffing ratio. The training services within this program
                will be tailored to increase and maintain the health of
                individuals who are diagnosed with mental illnesses or have a
                severe risk to their health and safety.
              </p>
            </div>
          </div>
        </div>
        <h2 className="pictures-title">Program Pictures</h2>
        <div className="masonry">
          <div className="item">
            <StaticImage
              src="../images/facilities/withone/withone-living1.jpg"
              alt="Living area"
              placeholder="blurred"
              width={400}
              height={500}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/withone/withone-back1.jpg"
              alt="Backyard"
              placeholder="blurred"
              width={400}
              height={500}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
